import React, { useCallback, useRef, useState, ReactElement } from "react";
import { ClickAwayListener } from "@remo-co/ui-core/src/components/ClickAwayListener";
import { ListItem } from "@remo-co/ui-core/src/components/ListItem";
import { Popper } from "@remo-co/ui-core/src/components/Popper";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { ArrowDropUpRounded } from "@remo-co/ui-core/src/icons/ArrowDropUpRounded";
import { Check } from "@remo-co/ui-core/src/icons/Check";
import { SettingsOutlined as Settings } from "@remo-co/ui-core/src/icons/SettingsOutlined";
import { useI18n } from "i18n";
import classNames from "classnames";
import { useHotkeys } from "react-hotkeys-hook";
import {
  selectIsMobile,
  encodeDevice,
  parseDeviceLabel,
  SerializedDevice,
} from "modules/deviceInfo";
import { useSelector } from "react-redux";
import { selectCurrentUserPreferences } from "modules/userPreferences/redux/selectors";
import { useAppDispatch } from "store/hooks";
import { showCameraSettingsDialog } from "modules/cameraSettings/redux/actions";
import { useStyles } from "./styles";

export interface Props {
  devices: SerializedDevice[];
  title: string;
  selectedDevice: SerializedDevice | MediaDeviceInfo | undefined | null;
  onSelect?: (device: SerializedDevice) => void;
  menuFooter?: (closePopover: () => void) => React.ReactNode;
  dark?: boolean;
  showCameraSettingsOption?: boolean;
}

const DeviceSelector = ({
  devices,
  title,
  selectedDevice,
  dark,
  onSelect,
  menuFooter,
  showCameraSettingsOption,
}: Props): ReactElement | null => {
  const isMobileView = useSelector(selectIsMobile);
  const buttonRef = useRef<null | HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget as HTMLElement);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    toggleIsOpen();
  };
  const toggleIsOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const closePopover = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);

      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    }
  }, [isOpen]);
  const { t } = useI18n(["conversation"]);
  const dispatch = useAppDispatch();
  const currentUserPreferences = useSelector(selectCurrentUserPreferences);
  const classes = useStyles({ darkMode: dark });

  const handleSelect = useCallback(
    (device: SerializedDevice) => {
      onSelect?.(device);
      setTimeout(() => closePopover(), 200);
    },
    [onSelect, closePopover],
  );

  const showCameraSettings = useCallback(() => {
    dispatch(showCameraSettingsDialog());
    setTimeout(() => closePopover(), 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSelect, closePopover]);

  useHotkeys("esc", closePopover, { filterPreventDefault: true }, [
    closePopover,
  ]);

  if (isMobileView) {
    return null;
  }

  if (!devices || !Array.isArray(devices)) {
    return null;
  }

  if (devices.length < 1) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={closePopover} mouseEvent="onMouseDown">
      <div className={classNames(classes.container)}>
        <Button
          id="device-selector-button"
          data-testid="device-selector-button"
          className={classes.button}
          aria-label={title}
          onClick={handleClick}
          variant="ghost"
          color="dark"
          ref={buttonRef}
        >
          <ArrowDropUpRounded className={classes.arrowIcon} />
        </Button>
        <Popper
          className={classes.popover}
          open={isOpen}
          anchorEl={anchorEl}
          popperOptions={{
            modifiers: {
              offset: {
                offset: "0,48",
              },
            },
          }}
          placement="top"
        >
          <div className={classes.popoverContent}>
            <Typography
              component="div"
              color="inherit"
              className={classes.header}
            >
              {title}
            </Typography>
            {devices?.map((device, index) => {
              const isChecked = selectedDevice
                ? encodeDevice(selectedDevice) === encodeDevice(device)
                : false;

              return (
                <ListItem
                  button
                  autoFocus={index === 0}
                  selected={isChecked}
                  data-testid={`device-option-${device.deviceId}`}
                  data-dd-action-name="Select AV Device"
                  key={device.deviceId}
                  className={classes.rowItem}
                  onClick={() => handleSelect(device)}
                >
                  {isChecked && (
                    <Check className={classNames(classes.rowIcon)} />
                  )}
                  <Typography>{parseDeviceLabel(device.label)}</Typography>
                </ListItem>
              );
            })}
            {menuFooter ? menuFooter(closePopover) : null}
            {showCameraSettingsOption && currentUserPreferences && (
              <ListItem
                button
                className={classes.rowItem}
                onClick={showCameraSettings}
              >
                <Settings className={classes.rowIcon} />
                <Typography color="inherit">
                  {t("conversation:camera.settings")}
                </Typography>
              </ListItem>
            )}
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default DeviceSelector;
