import { useDrop } from "react-dnd";
import { useMemo, useRef, useState } from "react";
import { useAttendeeList } from "modules/manageEvent";
import { useI18n } from "i18n";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { InputAdornment } from "@remo-co/ui-core/src/components/InputAdornment";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { ClickAwayListener } from "@remo-co/ui-core/src/components/ClickAwayListener";
import { Popper } from "@remo-co/ui-core/src/components/Popper";
import { Loader } from "@remo-co/ui-core/src/components/Loader";
import { PersonAdd } from "@remo-co/ui-core/src/icons/PersonAdd";
import { Search } from "@remo-co/ui-core/src/icons/Search";
import { Virtuoso } from "react-virtuoso";
import { DragItem, SettingUser } from "../../types";
import { DRAGGABLE_ATTENDEE_TYPE } from "../../constants";
import { filterAttendees, AttendeeTableUpdatePayload } from "../../utils";
import { InviteGuests } from "../InviteGuests";
import { UserRow } from "../UserRow";
import useStyles from "./styles";

interface Props {
  eventId: string;
  assignedAttendees: SettingUser[];
  removeAttendeeFromTable: (payload: AttendeeTableUpdatePayload) => void;
  size?: "sm" | "md";
  setHoveredUserId?: (id: string | null) => void;
}

const BUTTON_INVITE_GUESTS_COLOR = "black";

const AttendeeList = ({
  eventId,
  assignedAttendees,
  removeAttendeeFromTable,
  size,
  setHoveredUserId,
}: Props): JSX.Element => {
  const styles = useStyles({
    size,
  });
  const { t } = useI18n(["manageEvent"]);
  const [searchQuery, setSearchQuery] = useState("");
  const { invitedAttendees, isFetching } = useAttendeeList(eventId);
  const [showInviteGuests, setShowInviteGuests] = useState(false);
  const addGuestDialogButtonRef = useRef(null);

  const [_, drop] = useDrop(
    () => ({
      accept: DRAGGABLE_ATTENDEE_TYPE,
      drop: (item: DragItem) => {
        if (item.floorId && item.tableId) {
          removeAttendeeFromTable({
            attendee: item.user,
            floorId: item.floorId,
            tableId: item.tableId,
          });
        }
      },
    }),
    [removeAttendeeFromTable],
  );

  const filteredAttendees = useMemo(
    () =>
      filterAttendees(invitedAttendees ?? [], assignedAttendees, searchQuery),
    [invitedAttendees, assignedAttendees, searchQuery],
  );

  const handleCloseInviteDialog = () => {
    setShowInviteGuests(false);
  };

  const inviteAttendeesButton = (
    <Button
      variant="secondary"
      color="gray"
      size="sm"
      isIconButton
      onClick={() => setShowInviteGuests(true)}
      data-testid="attendee-add-button"
    >
      <PersonAdd
        fontSize="small"
        ref={addGuestDialogButtonRef}
        htmlColor={BUTTON_INVITE_GUESTS_COLOR}
      />
    </Button>
  );

  return (
    <>
      <Container
        flex
        alignItems="flex-start"
        justifyContent="flex-start"
        column
        className={styles.container}
      >
        {size !== "sm" && (
          <Container
            flex
            justifyContent="space-between"
            fullWidth
            alignItems="center"
          >
            <Typography variant="h5">{t("attendee.list")}</Typography>
            {inviteAttendeesButton}
          </Container>
        )}
        <Container fullWidth className={styles.searchContainer}>
          <Input
            size="sm"
            className={styles.searchField}
            inputStartAdornment={
              <InputAdornment
                position="start"
                className={styles.searchFieldInput}
              >
                <Search fontSize="small" />
              </InputAdornment>
            }
            value={searchQuery}
            onChange={(ev) => setSearchQuery(ev.target.value)}
            placeholder={t("search.attendees")}
          />
        </Container>
        <Box className={styles.unassignedCountContainer}>
          <Typography variant="caption">
            {t("unassigned", {
              key: filteredAttendees?.length ?? 0,
            })}
          </Typography>
        </Box>
      </Container>
      <Container
        ref={drop}
        column
        justifyContent="flex-start"
        className={styles.emailsContainer}
        data-testid="drop-container-attendee-list"
      >
        {isFetching ? (
          <Loader data-testid="loading" />
        ) : (
          <Virtuoso
            data={filteredAttendees}
            // eslint-disable-next-line react/no-unstable-nested-components
            itemContent={(_, attendee) => (
              <UserRow
                key={attendee.email}
                user={attendee}
                setHoveredUserId={setHoveredUserId}
              />
            )}
          />
        )}
      </Container>
      <ClickAwayListener
        onClickAway={handleCloseInviteDialog}
        mouseEvent="onMouseUp"
      >
        <Popper
          open={showInviteGuests}
          anchorEl={addGuestDialogButtonRef.current}
          popperOptions={{
            modifiers: {
              offset: {
                offset: "-4,10",
              },
            },
          }}
          placement="right-start"
          className={styles.popper}
        >
          <InviteGuests eventId={eventId} onClose={handleCloseInviteDialog} />
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default AttendeeList;
