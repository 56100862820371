import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "../../../../zIndexes.module.scss";

const baseZIndex = zIndexes.broadcastMenuZIndex;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    height: "30%",
    padding: theme.spacing(2),
    margin: "0 auto",
    color: "white",
    zIndex: baseZIndex - 1,
    position: "absolute",
    bottom: 0,
    marginBottom: theme.spacing(1),
    left: "50%",
    transform: "translateX(-50%)",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
  },
  message: {
    listStyleType: "none",
    width: "100%",
  },
  transcription: { width: "100%" },
  activeTranscription: {
    order: 1,
    transition: "order 0.5s ease-in-out",
  },
  messageFeed: {
    padding: 0,
    position: "absolute",
    bottom: 0,
    width: "100%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    left: 0,
    right: 0,
  },
}));

export default useStyles;
