import {
  useMemo,
  memo,
  useState,
  useCallback,
  useEffect,
  ReactElement,
  ChangeEvent,
} from "react";
import SwipeableViews from "react-swipeable-views";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { TabPanel } from "@remo-co/ui-core/src/components/TabPanel";
import classNames from "classnames";
import { ParticipantsContainer } from "modules/participants";
import { ChatContainer } from "modules/chat";
import RaisedHandUsersContainer from "modules/broadcastRaiseHand/RaisedHandUsersContainer";
import { useSelector } from "react-redux";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import {
  selectCurrentEventId,
  selectCurrentEventCompanyPlan,
  selectIsChatEnabled,
  selectIsEventManager,
  selectIsQnAEnabled,
  selectIsPollsEnabled,
} from "modules/event/selectors";
import { PollsAndQuizzesWrapper } from "modules/polls/components/PollsAndQuizzesWrapper";
import { TranscriptionSidebar } from "modules/transcription";
import { TranscriptionSidebar as TranscriptionSidebarV2 } from "modules/transcriptionV2";
import { useAppDispatch } from "store/hooks";
import { usePolls } from "modules/polls/hooks/usePolls";
import { useAudienceViewActions } from "modules/broadcast/AudienceView/useAudienceView";
import { getNewMessagesCount } from "modules/chat/chatUtils";
import { selectIsBroadcasting } from "modules/broadcast/redux/selectors";
import { selectQuestions } from "modules/qa/redux/selectors";
import { updateActiveChannel } from "modules/chat/redux/actions";
import {
  makeSelectActiveChannel,
  selectChatCountForActiveChannel,
  selectUnreadCount,
} from "modules/chat/redux/selectors";
import { QAContainer } from "modules/qa";
import areClosedCaptionsEnabled from "modules/companyPlanSettings/utils/areClosedCaptionsEnabled";
import { useManageChatRoom } from "modules/chat/hooks";
import { selectRaisedHandCount } from "modules/broadcastRaiseHand/redux/selectors";
import useEventSplitTreatment from "modules/split/useEventSplitTreatment";
import { EventFeatures } from "services/splitService/features";
import { Header } from "..";
import { TABS, setPanelState, selectRightPanelState } from "../../redux";
import { useStyles } from "./styles";

interface Props {
  onHideRightPanel: () => void;
}

export interface ITab {
  type: TABS;
  isEnabled: boolean;
  html: ReactElement;
  label: string;
  id: string;
  badgeContent: number;
}

const RightPanel = ({ onHideRightPanel }: Props): JSX.Element | null => {
  const { featureEnabled: transcriptionV2Enabled } = useEventSplitTreatment(
    EventFeatures.TRANSCRIPTION_V2,
  );
  const dispatch = useAppDispatch();

  const isBroadcasting = useSelector(selectIsBroadcasting);
  const isEventManager = useSelector(selectIsEventManager);
  const eventId = useSelector(selectCurrentEventId);
  const otherChannelsUnreadCount = useSelector(selectUnreadCount);
  const activeChannel = useSelector(makeSelectActiveChannel());
  const hasEventManagerAccess = useSelector(selectIsEventManager);
  const chatMessagesCount = useSelector(selectChatCountForActiveChannel);
  const theaterId = useSelector(selectCurrentTheaterId);
  const { isOpen, tabId } = useSelector(selectRightPanelState);
  const questions = useSelector(selectQuestions);
  const eventCompanyPlan = useSelector(selectCurrentEventCompanyPlan);
  const isChatEnabled = useSelector(selectIsChatEnabled);
  const isPollsEnabled = useSelector(selectIsPollsEnabled);
  const isQnAEnabled = useSelector(selectIsQnAEnabled);
  const raisedHandUsersCount = useSelector(selectRaisedHandCount);

  const [seenActiveChannelMessages, setSeenActiveChannelMessages] = useState(
    tabId === TABS.CHAT ? chatMessagesCount : 0,
  );
  const [seenHands, setSeenHands] = useState(0);
  const [seenQuestionsCount, setSeenQuestionsCount] = useState(
    tabId === TABS.QA ? questions.length : 0,
  );
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const { unansweredPolls } = usePolls();
  const { recomputeItemsPerRow } = useAudienceViewActions();
  useManageChatRoom();

  const styles = useStyles({ isBroadcasting: Boolean(isBroadcasting) });

  const showChatTab = useCallback(
    () => dispatch(setPanelState({ tabId: TABS.CHAT, isOpen: true })),
    [dispatch],
  );

  const hideHeader = !activeChannel;

  // Calculate the number of unread chat messages
  useEffect(() => {
    if (tabId === TABS.CHAT) {
      setSeenActiveChannelMessages(chatMessagesCount);
    }
  }, [chatMessagesCount, tabId]);

  const unseenActiveChannelMessages = Math.max(
    0,
    chatMessagesCount - seenActiveChannelMessages,
  );

  const newMessageCount = useMemo(
    () =>
      getNewMessagesCount(otherChannelsUnreadCount as number[]) +
      unseenActiveChannelMessages,
    [otherChannelsUnreadCount, unseenActiveChannelMessages],
  );

  // Calculate how many raised hands haven't been seen (it's always 0 if user
  // is not an event manager)
  useEffect(() => {
    if (tabId === TABS.PARTICIPANTS) {
      setSeenHands(raisedHandUsersCount);
    }
  }, [raisedHandUsersCount, tabId]);

  const unseenHands = hasEventManagerAccess
    ? Math.max(0, raisedHandUsersCount - seenHands)
    : 0;

  // Calculate how many Q&A questions are unread
  useEffect(() => {
    if (tabId === TABS.QA) {
      setSeenQuestionsCount(questions.length);
    }
  }, [questions.length, tabId]);

  const unseenQuestions = Math.max(0, questions.length - seenQuestionsCount);

  // The definition of each tab, including the badge values
  const tabs: ITab[] = useMemo(
    () =>
      [
        {
          type: TABS.CHAT,
          isEnabled: isChatEnabled,
          html: (
            <Box className={styles.chatContainer}>
              <ChatContainer />
            </Box>
          ),
          label: "chat.button",
          id: "bc-qa-chat",
          badgeContent: newMessageCount,
        },
        {
          type: TABS.PARTICIPANTS,
          isEnabled: true,
          html: <ParticipantsContainer switchChatTab={showChatTab} />,
          label: "participants",
          id: "bc-qa-participants",
          badgeContent: unseenHands,
        },
        {
          type: TABS.QA,
          isEnabled: isQnAEnabled,
          html: <QAContainer />,
          label: "q&a",
          id: "bc-qa-tab",
          badgeContent: unseenQuestions,
        },
        {
          type: TABS.CC,
          isEnabled:
            Boolean(isBroadcasting) &&
            areClosedCaptionsEnabled(eventCompanyPlan),
          html: transcriptionV2Enabled ? (
            <TranscriptionSidebarV2 />
          ) : (
            <TranscriptionSidebar />
          ),
          label: "CC",
          id: "bc-qa-cc",
          badgeContent: 0,
        },
        {
          type: TABS.POLLS,
          isEnabled: Boolean(eventId) && isPollsEnabled,
          html: <PollsAndQuizzesWrapper eventId={eventId ?? ""} />,

          label: "Polls",
          id: "bc-qa-polls",
          badgeContent: isEventManager ? 0 : unansweredPolls.length,
        },
      ].filter((tab) => tab.isEnabled),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isEventManager,
      unansweredPolls.length,
      eventId,
      isChatEnabled,
      isQnAEnabled,
      isPollsEnabled,
      hideHeader,
      newMessageCount,
      unseenHands,
      unseenQuestions,
    ],
  );

  const handleTabChange = useCallback(
    (_event: ChangeEvent<Record<string, never>> | null, index: number) => {
      if (tabId === TABS.CHAT && tabs[index].type !== TABS.CHAT) {
        dispatch(updateActiveChannel(null));
      }
      if (tabId === TABS.CHAT && tabId === tabs[index].type) {
        dispatch(updateActiveChannel(null));
      } else if (tabId !== tabs[index].type) {
        dispatch(setPanelState({ tabId: tabs[index].type, isOpen: true }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabId, tabs],
  );

  useEffect(() => {
    // convert from known tab type to dynamic array index to use in material Tabs
    const index = tabs.findIndex((tab) => tab.type === tabId);

    if (index > -1) {
      setCurrentTabIndex(index);
    } else {
      // set first not disabled tab
      dispatch(setPanelState({ tabId: tabs[0].type, isOpen }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabId, tabs]);

  if (!theaterId) {
    return null;
  }

  const renderTabsContent = () =>
    tabs.map((tab, index: number) => (
      <TabPanel
        className={styles.tabPanel}
        value={currentTabIndex}
        index={index}
        key={`tab-panel-${tab.id}`}
        style={{ overflowY: "auto", position: "absolute" }}
      >
        {currentTabIndex === index && tab.html}
      </TabPanel>
    ));

  return (
    <div
      onTransitionEnd={() => {
        recomputeItemsPerRow();
      }}
      className={classNames(
        styles.rightPanelContainer,
        { [styles.attendee]: !hasEventManagerAccess && isOpen },
        { [styles.hidden]: !isOpen },
      )}
      data-testid="right-panel"
      data-cy="right-panel"
    >
      <div className={styles.rightPanel}>
        <Header
          theaterId={theaterId}
          tabId={currentTabIndex}
          onHideRightPanel={onHideRightPanel}
          handleTabChange={handleTabChange}
          tabs={tabs}
        />
        <RaisedHandUsersContainer theaterId={theaterId} />
        <div className={styles.tabsContent}>
          <SwipeableViews axis="x" disableLazyLoading index={currentTabIndex}>
            {renderTabsContent()}
          </SwipeableViews>
        </div>
      </div>
    </div>
  );
};

export default memo(RightPanel);
