export enum Errors {
  ACCEPT_TABLE_INVITE = "Accept invite to table flow",
  ADBLOCK_DETECTION = "AdBlock detection failed",
  AGENCY_WHITE_LABEL_SETTINGS = "Agency White Label Settings",
  ANONYMOUS_LOG_IN = "Failed to Log in user anonymously",
  ASSIGN_TABLES_ALL_ATTENDEE_INVITE = "Assign Table Event Attendee Invite",
  ASSIGN_TABLES_ALL_ATTENDEE_MOVE = "Assign Table Event Attendee Move",
  COMPANY_SAVE = "Company Save",
  COMPANY_SETTING_QUERY = "Get Company Settings",
  COMPANY_TIMEZONE = "Company Timezone Update",
  CUSTOM_REGISTRATION_ANSWERS_QUERY = "Get Custom Registration Answers",
  CUSTOM_REGISTRATION_ANSWERS_SUBMIT = "Submit Custom Registration Answers",
  CUSTOM_REGISTRATION_QUESTIONS_CREATE = "Create Custom Registration Question",
  CUSTOM_REGISTRATION_QUESTIONS_DELETE = "Delete Custom Registration Question",
  CUSTOM_REGISTRATION_QUESTIONS_QUERY = "Get Custom Registration Questions",
  CUSTOM_REGISTRATION_QUESTIONS_REORDER = "Reorder Custom Registration Question",
  CUSTOM_REGISTRATION_QUESTIONS_UPDATE = "Update Custom Registration Question",
  DISABLE_VPN_CHECK_UPDATE = "Company Settings - Disable VPN Check",
  EMBED_CONTENT_CREATE_MUTATION = "Create Embed Content",
  EMBED_CONTENT_DELETE_MUTATION = "Delete Embed Content",
  EMBED_CONTENT_QUERY = "Get Embed Content",
  EMBED_CONTENT_SUBSCRIPTION = "Embed Content Subscription",
  EVENT_ANALYTICS_EMBED_URL = "Failed to fetch event analytics embed URL",
  EVENT_CONTENT_CHANGED_SUBSCRIPTION = "Event Content Updates Subscription",
  EVENT_CONTENT_CREATE_MUTATION = "Create Event Content",
  EVENT_CONTENT_DELETED_SUBSCRIPTION = "Event Content Deletions Subscription",
  EVENT_CONTENT_DELETE_MUTATION = "Delete Event Content",
  EVENT_CONTENT_MAPPING = "Event Content Mapping",
  EVENT_CONTENT_QUERY = "Get Event Content",
  EVENT_CONTENT_UPDATE_MUTATION = "Update Event Content",
  EVENT_DISCOVERY = "Event Discovery",
  EVENT_DISCOVERY_DEFAULT_UPDATE = "Company Settings - Event Discovery Default Update",
  EVENT_FLOOR_CONTENT_CHANGED_SUBSCRIPTION = "Event Content Floor Updates Subscription",
  EVENT_FLOOR_CONTENT_DELETED_SUBSCRIPTION = "Event Content Floor Deletions Subscription",
  EVENT_FLOOR_CONTENT_QUERY = "Get Event Floor Content",
  EVENT_PRESET_SEAT_ASSIGNMENTS = "Get Preset Seat Assingments",
  EVENT_PRESET_SEAT_ASSIGNMENTS_SAVE = "Save Preset Seat Assingments",
  EVENT_TABLE_SETTINGS = "Get Event Table Settings",
  EVENT_TABLE_SETTINGS_SAVE = "Update Event Table Settings",
  EVENT_TABLE_SETTING_PRESETS = "Get Event Table Setting Presets",
  EVENT_TABLE_SETTING_PRESETS_CREATE = "Create Event Table Setting Presets",
  EVENT_TABLE_SETTING_PRESETS_DELETE = "Delete Event Table Setting Presets",
  EVENT_TICKET_QUERY = "Get Event Tickets",
  FB_HANDLE_INVITED = "Firebase handle invited",
  FETCH_VPN_CHECK_DISABLED = "Fetch check vpn disabled",
  FOCUSED_SCREEN_SUBSCRIBE = "Focused screen subscribe",
  GET_ATTENDEE_LIST = "Get Attendee List Error",
  GET_CURRENCY_SYMBOL = "Get Currency Symbol Error",
  GET_DEPLOYED_VERSION = "Get Latest Version",
  GET_EVENTS = "Get Events Error",
  GET_STARTED_PLAN_CHECK = "Get Started Page - Plan Check",
  GET_USER_MEDIA = "Get User Media",
  GRAPHQL_NETWORK_ERROR = "GraphQL Network Error",
  GRAPHQL_RETRY_MAX = "Graphql: Max retry attempts exceeded",
  GUEST_EXPERIENCE_SURVEY_GET_EVENT_RATING = "Guest Experience Survey Get Event Rating",
  GUEST_EXPERIENCE_SURVEY_MUTATE_EVENT_RATING = "Guest Experience Survey Mutate Event Rating",
  MEDIA_DEVICE_ENUM = "Media Device Enumeration",
  MIC_CAM_ERROR = "Microphone Camera Error",
  PASSWORD_RESET_FAILED = "Password reset error",
  POLLS_SUBSCRIPTION_ERROR = "Error with polls subscription",
  PRESENTATION_MODE_GET_ATTENDEE_ON_STAGE = "Presentation Mode Get Attendee OnStage",
  RECORDING_ERROR = "Recording Operation Error",
  RESTART_EVENT = "Restart Event",
  SEND_ATTENDEE_INVITE = "Send Attendee Invite",
  SET_FOCUSED_SCREEN = "Set Focused Screen",
  SHUFFLE_START = "Shuffle start - unable to set document in Firestore",
  SUBSCRIPTION_CANCEL = "Cancel Subscription",
  TEST = "only used for unit testing",
  TICKET_ORDER_REGISTRATION_FAILED = "Ticket Order Registration Failed",
  TRANSCRIPTION_REQUEST_CLEAR_ALL = "Clear All Transcription Requests",
  TRANSCRIPTION_REQUEST_REGISTER = "Register Transcription Request",
  TRANSCRIPTION_REQUEST_REVOKE = "Revoke Transcription Request",
  TRANSCRIPTION_V2 = "Transcription V2",
  UNEXPECTED_FACEBOOK_SSO_ERROR = "Unexpected error in Facebook SSO authentication",
  UNEXPECTED_GOOGLE_SSO_ERROR = "Unexpected error in Google SSO authentication",
  UNEXPECTED_LINKEDIN_SSO_ERROR = "Unexpected error in Linkedin SSO authentication",
  UNEXPECTED_MS_SSO_ERROR = "Unexpected error in MS SSO authentication",
  UNHANDLED_ERROR = "Unhandled Error",
  UNHANDLED_EVENT_CHECK = "Unhandled Event Check",
  UNREACHABLE_THIRD_PARTY_SERVICE = "Unreachable endpoint",
  UPDATE_LOGIN_OPTIONS = "Update Login Options",
  VIRTUAL_BACKGROUND = "Virtual Background Error",
  VPN_DETECTION = "VPN detection failed",
  VPN_QUOTA_LIMIT_REACHED = "VPN quota limit reached",
}
