export enum EventFeatures {
  ATTENDEE_MANAGEMENT = "event-attendee-management",
  PRESENCE = "presence",
  SPEAKING_WAVES = "speaking-waves",
  TEST_FEATURE = "test-feature",
  TRANSCRIPTION_V2 = "transcription-v2",
  USERPILOT = "userpilot",
  VIDEO_OPTIMIZATION = "video-optimization",
}

export enum UserFeatures {
  MULTIPLE_EVENT_DIRECTORIES = "multiple-event-directories",
  SPEAKER_LAYOUT = "speaker-layout",
  TEST_FEATURE = "test-feature",
  USER_SESSION = "user-session",
}

export enum CompanyFeatures {
  EVENT_REGISTRATION_QUESTIONS = "event-registration-questions",
  TEST_FEATURE = "test-feature",
  TICKETING = "ticketing-host-functionality",
}
