import { useContext, useCallback } from "react";
import { useSelector } from "react-redux";
import { ActionButton } from "modules/actionButton";
import {
  useDailyEvent,
  useLocalSessionId,
  useScreenShare,
} from "@daily-co/daily-react";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useI18n } from "i18n";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { Actions, trackAction } from "modules/monitoring";
import { usePresentationLayout } from "modules/audioVideo/hooks/useLayout";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { useActivePresenterActions } from "modules/audioVideo/hooks/usePresenters";
import { showDevicePromptPopup } from "modules/audioVideo/redux/slice";
import { BlockedBy, PermissionState } from "modules/deviceInfo";
import { ScreenSharePopupState } from "modules/audioVideo/types";
import { useAppDispatch } from "store/hooks";
import { screenShareEncodings } from "modules/audioVideo/constants";
import { hotKeys } from "../../utils";
import { ReactComponent as ShareScreenIcon } from "./icons/share-screen-icon.inline.svg";
import { StopScreenShareEvent } from "./components/StopScreenShareEvent";

const icon = <ShareScreenIcon />;

interface Props {
  darkMode?: boolean;
}

const ScreenSharingButton = ({ darkMode = false }: Props): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const participantId = useLocalSessionId();
  const { addActiveTile } = usePresentationLayout();
  const { addActivePresenter } = useActivePresenterActions();
  const { track } = useContext(TRACKING_CONTEXT);
  const { startScreenShare, stopScreenShare, isSharingScreen } =
    useScreenShare();
  const theaterId = useSelector(selectCurrentTheaterId);

  useDailyEvent(
    "nonfatal-error",
    useCallback(
      (error) => {
        if (error.type === "screen-share-error") {
          if (error.errorMsg.includes("blocked-by-browser")) {
            dispatch(
              showDevicePromptPopup({
                state: PermissionState.BLOCKED,
                screenShare: true,
                blockedBy: BlockedBy.BROWSER,
              } as ScreenSharePopupState),
            );
          } else if (error.errorMsg.includes("blocked-by-os")) {
            dispatch(
              showDevicePromptPopup({
                state: PermissionState.BLOCKED,
                screenShare: true,
                blockedBy: BlockedBy.OS,
              } as ScreenSharePopupState),
            );
          } else {
            dispatch(
              showDevicePromptPopup({
                state: PermissionState.UNKNOWN,
                screenShare: true,
              } as ScreenSharePopupState),
            );
          }
        }
      },
      [dispatch],
    ),
  );

  const toggleScreenShare = useCallback(() => {
    if (isSharingScreen) {
      trackAction(Actions.SCREEN_SHARE_STOP);
      stopScreenShare();
      return;
    }

    startScreenShare({
      screenVideoSendSettings: {
        maxQuality: "low",
        encodings: screenShareEncodings,
      },
    });
    // TODO: conditional logic for presentation mode spread around this function.
    if (theaterId && isInBroadcast) {
      addActiveTile({ theaterId, streamId: `${participantId}-screen` });
      addActivePresenter();
    }

    track(Events.SCREEN_SHARE_START);
    trackAction(Actions.SCREEN_SHARE_START);
  }, [
    track,
    theaterId,
    addActiveTile,
    isInBroadcast,
    participantId,
    isSharingScreen,
    stopScreenShare,
    startScreenShare,
    addActivePresenter,
  ]);

  return (
    <div>
      <ActionButton
        data-testid="screen-share-button"
        onClick={toggleScreenShare}
        label={t("button.share.screen")}
        isActive={isSharingScreen}
        hotKey={hotKeys.toggleScreen.keyName}
        icon={icon}
        darkMode={darkMode}
        data-cy="share-button"
      />
      <StopScreenShareEvent />
    </div>
  );
};

export default ScreenSharingButton;
