import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

export const selectCompany = (state: RootState) => state.company;

export const selectMyCompany = createSelector(
  selectCompany,
  (company) => company.company,
);

export const selectCompanies = createSelector(
  selectCompany,
  (company) => company.companies,
);

export const selectMultipleCompanyView = createSelector(
  selectCompany,
  (company) => company.multipleCompanyView,
);

export const selectCompanyId = createSelector(
  selectCompany,
  (company) => company.company?.id ?? null,
);

export const selectMaxInTable = createSelector(
  selectCompany,
  (company) => company?.companyPlan?.settings?.theater?.maxInTable,
);

export const selectIsLoading = createSelector(
  selectCompany,
  (company) => company.isLoading,
);
