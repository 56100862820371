import { useMemo, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "i18n";
import { ActionButton } from "modules/actionButton";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { DialogType } from "modules/dialogNotification/constants";
import { selectCurrentEventId } from "modules/event/selectors";
import { selectCurrentSpaceId } from "modules/space/redux/selectors";
import { selectCurrentRoomId } from "store/rooms";
import { ReactComponent as UnassignIcon } from "../../assets/person-minus-icon.inline.svg";
import {
  useUpdateEventTableSettings,
  useEventTableSettings,
} from "../../hooks";
import { tableHasSeatAssignments, floorHasSeatAssignments } from "../../utils";
import {
  UnassignMenuDialogContent,
  UNASSIGN_OPTIONS,
} from "../UnassignMenuDialogContent";

interface Props {
  enableClickAway: () => void;
  disableClickAway: () => void;
}

const UnassignMenuButton = ({
  enableClickAway,
  disableClickAway,
}: Props): JSX.Element | null => {
  const currentEventId = useSelector(selectCurrentEventId);
  const floorId = useSelector(selectCurrentSpaceId);
  const tableId = useSelector(selectCurrentRoomId);
  const { tableData: tableAssignmentData, assignedAttendees } =
    useEventTableSettings(currentEventId);
  const {
    unassignAttendeesFromTable,
    unassignAttendeesFromEvent,
    unassignAttendeesFromFloor,
  } = useUpdateEventTableSettings(currentEventId);
  const { openDialog, updateDialogParams } = useDialogNotificationActions();
  const { addSuccessNotification } = useNotificationActions();
  const { t } = useI18n(["event"]);

  const showTableOption = useMemo(
    () => tableHasSeatAssignments(tableAssignmentData, floorId, tableId),
    [tableAssignmentData, floorId, tableId],
  );
  const showFloorOption = useMemo(
    () => floorHasSeatAssignments(tableAssignmentData, floorId),
    [tableAssignmentData, floorId],
  );

  const handleConfirm = (unassignAllFrom: string) => {
    if (!floorId || !tableId) {
      return;
    }

    updateDialogParams(DialogType.UNASSIGN_ALL_ATTENDEES_CONVERSATION_MODE, {
      confirmLoading: true,
    });
    switch (unassignAllFrom) {
      case UNASSIGN_OPTIONS.TABLE:
        unassignAttendeesFromTable(floorId, tableId);
        addSuccessNotification({
          message: t("manageEvent:unassign.table.success"),
          position: "tc",
        });
        break;

      case UNASSIGN_OPTIONS.FLOOR:
        unassignAttendeesFromFloor(floorId);
        addSuccessNotification({
          message: t("manageEvent:unassign.floor.success"),
          position: "tc",
        });
        break;

      default:
        unassignAttendeesFromEvent();
        addSuccessNotification({
          message: t("manageEvent:unassign.event.success"),
          position: "tc",
        });
        break;
    }
    enableClickAway();
  };

  const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateDialogParams(DialogType.UNASSIGN_ALL_ATTENDEES_CONVERSATION_MODE, {
      content: (
        <UnassignMenuDialogContent
          onChange={handleOptionChange}
          selectedOption={e.target.value}
          showFloorOption={showFloorOption}
          showTableOption={showTableOption}
        />
      ),
      onConfirm: () => handleConfirm(e.target.value),
    });
  };

  const handleClick = () => {
    if (!assignedAttendees.length) {
      addSuccessNotification({
        message: t("manageEvent:no.guests.assigned"),
        position: "tc",
      });

      return;
    }

    disableClickAway();
    openDialog({
      confirmBtnId: "toggle-confirm",
      dismissBtnId: "toggle-dismiss",
      content: (
        <UnassignMenuDialogContent
          onChange={handleOptionChange}
          selectedOption={UNASSIGN_OPTIONS.EVENT}
          showFloorOption={showFloorOption}
          showTableOption={showTableOption}
        />
      ),
      onConfirm: () => handleConfirm(UNASSIGN_OPTIONS.EVENT),
      onDismiss: () => enableClickAway(),
      confirmText: t("common:remove"),
      dismissText: t("common:button.cancel"),
      hideCloseButton: true,
      type: DialogType.UNASSIGN_ALL_ATTENDEES_CONVERSATION_MODE,
    });
  };

  // TODO icon below also exists on MUI icons but our dep is not updated,
  // replace use here once updated GH #7739
  return (
    <ActionButton
      title={t("event:unassign.all.seats")}
      label={t("event:unassign.all.seats")}
      onClick={handleClick}
      isPopoverButton
      icon={<UnassignIcon width={34} height={34} viewBox="-4 -10 34 34" />}
    />
  );
};

export default UnassignMenuButton;
