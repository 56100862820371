import { ComponentProps } from "react";
import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "../../../../zIndexes.module.scss";
import AttendeeList from "./AttendeeList";

const useStyles = makeStyles<
  Theme,
  Partial<ComponentProps<typeof AttendeeList>>
>((theme) => ({
  container: {
    padding: (props) => (props.size === "sm" ? "0px 8px 0" : "20px 15px 0"),
  },
  searchContainer: {
    marginTop: 10,
  },
  searchField: {
    margin: 0,
    width: "100%",
    borderColor: theme.palette.gray.light,
    "&:hover": {
      borderColor: theme.palette.gray.light,
    },
  },
  searchFieldInput: {
    borderRadius: 5,
    paddingLeft: theme.spacing(1.5),
  },
  unassignedCountContainer: {
    margin: "15px 0px 10px",
    cursor: "default",
  },
  emailsContainer: {
    overflowY: "auto",
    minHeight: 50,
    height: "100%",
  },
  popper: {
    zIndex: zIndexes.inviteGuestsZIndex,
    background: "white",
    border: `1px solid ${theme.palette.gray.disabled}`,
    borderRadius: 5,
    overflow: "hidden",
  },
}));

export default useStyles;
