import { DailyTranscriptionDeepgramOptions } from "@daily-co/daily-js";

export const DEFAULT_TRANSCRIPTION_CONFIG: DailyTranscriptionDeepgramOptions = {
  punctuate: true,
  includeRawResponse: true,
  model: "nova-2",
  endpointing: 500,
  extra: {
    interim_results: true,
    smart_format: true,
    numerals: true,
    no_delay: true,
  },
};
