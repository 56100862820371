import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useClearFloorDataAndRegisterListeners } from "modules/joinFloor/hooks/useClearFloorDataAndRegisterListeners";
import {
  changeSpace,
  changeSpaceCompleted,
} from "modules/space/redux/spaceSlice";
import { selectCurrentSpaceId } from "modules/space/redux/selectors";
import { useUserData } from "modules/audioVideo/hooks";
import { useAppDispatch } from "store/hooks";
import request from "./request";
import { EnforceAssignmentErrors } from "../../constants";

// deliberately not handling errors here as we expect a negative response unless a move was conducted
const useConfirmAttendeeFloor = (eventId?: string | null) => {
  const dispatch = useAppDispatch();
  const currentFloorId = useSelector(selectCurrentSpaceId);
  const { setConversationId } = useUserData();
  const { clearFloorDataAndRegisterListeners } =
    useClearFloorDataAndRegisterListeners();

  const { mutate: confirmAttendeeFloor, status } = useMutation(
    async (presetId?: string) => {
      if (!eventId) {
        throw new Error(EnforceAssignmentErrors.NO_EVENT_ID);
      }

      return request(eventId, presetId);
    },
    {
      onSuccess: async ({ floorId, tableId }) => {
        if (floorId && currentFloorId !== floorId && tableId) {
          dispatch(changeSpace());
          await setConversationId({ conversationId: tableId });

          setTimeout(() => {
            clearFloorDataAndRegisterListeners(floorId);

            dispatch(changeSpaceCompleted());
          }, 500);
        } else if (tableId) {
          await setConversationId({ conversationId: tableId });
        }
      },
    },
  );

  return {
    confirmAttendeeFloor,
    status,
  };
};

export default useConfirmAttendeeFloor;
